import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { prepare_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import InformationIcon from "../../../../assets/icons/information-icon.svg";
import ReviseIcon from "../../../../assets/icons/revise.svg";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import Tooltip from "../../global/tooltip/Tooltip";

const SectionalTestHeader = ({ information, tests, testModule }) => {
  const login = useSelector(loginDetails);
  const history = useHistory();

  const path = history?.location?.pathname;

  const tooltipText = {
    reviseQue: "Revise the questions across all completed tests.",
  };

  function getTestData() {
    return prepare_apirequest({
      url: `${endpoint.getTestData.addr}`,
      method: "post",
      headers: {
        "access-token": login.token,
      },
      data: {
        assetId: tests && tests[0].assetId,
        assessmentId: tests[0]?.assetDetails[0]?.assessmentId,
        redirectUrl: window.location.href,
      },
    });
  }

  const { refetch: getTestDataApi } = useQuery(
    [endpoint.getTestData.key],
    getTestData,
    {
      enabled: false,
      onSuccess: (res) => {
        if (res.data.url) {
          // changes for test issues
          Cookies.set("test_engine_auth_token", res.data.token, {
            domain: ".imsindia.com",
            secure: true,
          });
          window.open(
            `https://bete.imsindia.com/api/test/revision/?test_ids=${tests
              .map((test) => `${test?.assetDetails[0].assessmentId}`)
              .join(",")}`,
            "Popup"
          );

          return;
        } else {
          alert("Error");
        }
      },
    }
  );
  const handleClick = () => {
    if (tests.length === 0) {
      return;
    }
    getTestDataApi();
  };

  const hideReviseButton =
    testModule && path && path.toLowerCase().includes("nmat");
  return (
    <>
      {!hideReviseButton && (
        <div className="flex flex-row space-between items-center absolute right-16">
          <div
            className={`flex space-x-2 cursor-pointer ${
              tests.length === 0 ? "hidden" : "cursor-pointer"
            }`}
          >
            <img src={ReviseIcon} alt="revise" />
            <span
              className="font-ibm font-normal text-lg"
              data-tip
              data-for="registerTip"
              onClick={handleClick}
            >
              Revise
            </span>

            {information && <img src={InformationIcon} alt="information" />}
          </div>
          {
            <Tooltip
              multiline={true}
              id="registerTip"
              place="bottom"
              text={
                <div className="m-0">
                  {tooltipText.reviseQue.substring(
                    0,
                    tooltipText.reviseQue.length / 2
                  )}
                  <br />
                  {tooltipText.reviseQue.substring(
                    tooltipText.reviseQue.length / 2,
                    tooltipText.reviseQue.length
                  )}
                </div>
              }
            />
          }
        </div>
      )}
    </>
  );
};

SectionalTestHeader.propTypes = {
  information: PropTypes.bool.isRequired,
  tests: PropTypes.array,
  testModule: PropTypes.bool,
};

export default SectionalTestHeader;
