import Cookies from "js-cookie";
import moment from "moment";
import propTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { prepare_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import myAnalytics from "../../../../assets/icons/analytics.svg";
import { ReactComponent as ExcellentIcon } from "../../../../assets/icons/excellent.svg";
import { ReactComponent as ProgressIcon } from "../../../../assets/icons/in-process.svg";
import { ReactComponent as HtmlIcon } from "../../../../assets/icons/pdf.svg";
import { ReactComponent as PercentileIcon } from "../../../../assets/icons/percentile.svg";
import myTaskActive from "../../../../assets/icons/plusActive.svg";
import myTask from "../../../../assets/icons/plusInActive.svg";
import { ReactComponent as PdfIcon } from "../../../../assets/icons/quiz-new.svg";
import { ReactComponent as ScoreIcon } from "../../../../assets/icons/score.svg";
import { ReactComponent as AssesmentIcon } from "../../../../assets/icons/testnew.svg";
import { ReactComponent as CompletedIcon } from "../../../../assets/icons/tick-circle-20px-active.svg";
import { ReactComponent as PlaylistIcon } from "../../../../assets/icons/video-playlist.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/icons/video.svg";
import { ModuleContext } from "../../../../pages/prepare/Prepare";
import {
  setContinueLearningTitle,
  setParentIdOfContinueLearning,
  setParentIdOfPlaylist,
  setPlaylist,
  setPlaylistName,
} from "../../../../redux/features/assetDetail/assetDetailSlice";
import { selectCurrentHeaderTab } from "../../../../redux/features/header/headerSlice";
import {
  getCourseId,
  getVariantId,
  loginDetails,
} from "../../../../redux/features/login/LoginSlice";
import CardBookMark from "../../home/cards/masterClass/CardBookMark";
import ErrorHandlerPopUp from "../errorHandlerPrompt/ErrorHandlerPopUp";
import Icon from "../icons/Icon";
import Tag from "../tags/Tag";
import Tooltip from "../tooltip/Tooltip";

import BookingConfimMain from "../../modal/Confirmation/BookingConfimMain";
import BookingSlotModalTrigger from "../../modal/ModalScreens/BookSlot/BookingSlotModalTrigger";

const Topic = ({
  asset,
  parentId,
  isButtons = true,
  title,
  clearPrepareSlicePlaylist = false,
  isSelectedInPlaylist,
  isSetContinueLearningParentId,
  moduleIdForChannel = null,
  variantDetails,
  bookedSlots,
  testModule,
  testCerebryData,
}) => {
  const dispatch = useDispatch();
  const [bookmarked, setBookmarked] = useState(false);
  const [task, setTask] = useState(asset.isMyTask === "1" ? true : false);
  const paramsQuery = useParamsQuery();
  let moduleId =
    moduleIdForChannel ||
    paramsQuery.get("moduleId") ||
    useSelector((state) => state.header.headerId);
  const login = useSelector(loginDetails);
  const courseId = useSelector(getCourseId);
  const variantId = useSelector(getVariantId);
  const headerTab = useSelector(selectCurrentHeaderTab);
  const [benchmarkCerebryUrl, setBenchmarkCerebryUrl] = useState(null);

  const history = useHistory();
  const duration = "assetId" in asset ? asset.duration : asset.playListDuration;
  const module = useContext(ModuleContext);
  const iframeRef = React.useRef();

  function useParamsQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const assessmentId = asset?.assetDetails
    ? asset?.assetDetails[0]?.assessmentId
    : null;
  const formatDuration = (duration) => {
    if (!duration) return "";
    let [hour, min] = duration?.split(":");
    return (
      (hour !== "0" || min !== "00") && (
        <div>
          <p>
            {hour !== "0"
              ? `${hour}hr : ${min} mins`
              : min !== "00" && `${min} mins`}
          </p>
        </div>
      )
    );
  };

  function handleClick() {
    localStorage.setItem("breadcrumb3", title);
    localStorage.setItem("breadcrumb4", asset.assetName || asset.name);
    if (asset.assetType === "Assessment") {
      let now = moment();
      if (asset?.assetDetails[0]?.assessmentType === "In-Class Quizzes") {
        let classDate =
          asset.classStartDate === "" ? "" : moment(asset.classStartDate);
        if (classDate !== "" && now.isBefore(classDate)) return;
      }
      let releaseDate = asset?.assetDetails[0]?.releaseDate;
      if (releaseDate !== "" && now.isBefore(releaseDate)) return;
      getTestDataApi();
      return;
    }

    // if the playlist is clicked in prepareListing page then set the learningPathId of the playlist as the parentId
    if (!("assetId" in asset)) {
      dispatch(setPlaylistName(asset.name));
      dispatch(setParentIdOfPlaylist(asset.learningPathId));
    }
    // if its from prepareDetail playlist then clear the playlist in prepareSlice
    if (clearPrepareSlicePlaylist) {
      dispatch(setPlaylist([]));
    }
    if (isSetContinueLearningParentId) {
      dispatch(setParentIdOfContinueLearning(parentId));
    }

    // if it is from prepare listing (both accordian with scroll and without)
    // dispatch(clearPlaylistAndPathData());
    dispatch(setContinueLearningTitle(title));

    if (headerTab.toLowerCase() === "home") {
      moduleId = login.channelData?.moduleId?.toString();
      history.push(
        `/home/homedetail/${asset.assetName?.replaceAll(
          "/",
          "-"
        )}/?moduleId=${moduleId}&parentId=${parentId}&assetId=${
          asset.assetId || asset.learningPathId
        }`
      );
      return;
    }

    history.push(
      `/prepare-${headerTab}/detail/${encodeURIComponent(
        asset.assetName?.replace("/", "-") || asset.name.replace("/", "-")
      )}/?moduleId=${moduleId}&parentId=${parentId}&assetId=${
        asset.assetId || asset.learningPathId
      }`
    );
  }

  function returnIcon() {
    if (!asset.assetId || "learningPathId" in asset) return <PlaylistIcon />;
    if (asset.assetType?.toLowerCase() === "video") return <VideoIcon />;
    if (asset.assetType?.toLowerCase() === "html") return <HtmlIcon />;
    if (asset.assetType?.toLowerCase() === "pdf") return <PdfIcon />;
    if (asset.assetType?.toLowerCase() === "assessment")
      return <AssesmentIcon />;
  }
  //returnStatusIcon
  function returnStatusIcon(status) {
    let returnValue;
    if (status?.toLowerCase() == "completed" || status?.toLowerCase() == "true")
      returnValue = <CompletedIcon className="ml-8" />;
    else if (
      status?.toLowerCase() == "in-progress" ||
      status?.toLowerCase() == "false"
      // asset?.assetType?.toLowerCase() == "video"
    )
      returnValue = <ProgressIcon />;
    else returnValue = "";
    return returnValue;
  }

  function addMyTask() {
    return prepare_apirequest({
      url: `${endpoint.addMyTasks.addr}`,
      method: "POST",
      headers: {
        "access-token": login.token,
      },
      data: {
        studentImsPin: login.student_imspin,
        courseId,
        variantId,
        moduleId,
        learningPathId: parentId,
        assetId: asset.assetId || asset.learningPathId,
        myTask: task ? "0" : "1",
        createdBy: login.student_imspin,
        assetPath: asset.assetId ? "0" : "1",
      },
    });
  }

  const { refetch: addToMyTask } = useQuery(
    [endpoint.addMyTasks.key],
    addMyTask,
    {
      enabled: false,
    }
  );

  function handleMyTaskClick() {
    setTask(!task);
    addToMyTask();
  }

  const openPopup = () => {
    const iframe = document.getElementById("iframe"); // Assuming you have an iframe element with id "iframe"
    if (iframe) {
      const newWindow = window.open(
        iframe.getAttribute("src"),
        "Dynamic Popup",
        "height=${iframe.clientHeight}, width=${iframe.clientWidth}, scrollbars=auto, resizable=no, location=no, status=no"
      );

      var timer = setInterval(function () {
        if (newWindow.closed) {
          clearInterval(timer);
          window.location.reload();
        }
      }, 1000);

      if (newWindow) {
        newWindow.document.write(iframe.outerHTML);
        newWindow.document.write(
          '<script>window.addEventListener("message", function(event) {window.close();});</script>'
        );
        newWindow.document.close();
        iframe.outerHTML = "";

        setBenchmarkCerebryUrl(newWindow);
      }
    }
  };
  // const handleMyAnalytics =()=>{
  //   history.push("/test/Detailed Analytics");
  // };
  function getTestData() {
    return prepare_apirequest({
      url: `${endpoint.getTestData.addr}`,
      method: "post",
      headers: {
        "access-token": login.token,
      },
      data: {
        assetId: asset.assetId,
        assessmentId: asset?.assetDetails[0]?.assessmentId,
        redirectUrl: window.location.href,
      },
    });
  }

  const [errorPopUpOpen, setErrorPopUpOpen] = useState(false);

  const { refetch: getTestDataApi } = useQuery(
    [endpoint.getTestData.key, asset.assetId],
    getTestData,
    {
      enabled: false,
      onSuccess: (res) => {
        if (res.data.url) {
          // changes for test issues
          Cookies.set("test_engine_auth_token", res.data.token, {
            domain: ".imsindia.com",
            secure: true,
          });
          /* Storing user's device details in a variable */
          let details = navigator.userAgent;

          /* Creating a regular expression  
   containing iOS and Safari keywords to search in details string */
          let iosSafariRegexp = /iphone|ipad|ipod/i;
          // let safariRegexp = /safari/i;
          // let chromeRegexp = /crios|chrome/i;

          /* Using test() method to search regexp in details 
   it returns boolean value */
          let isIOSDevice = iosSafariRegexp.test(details);
          // let isSafariBrowser =
          //   safariRegexp.test(details) && !chromeRegexp.test(details);

          if (isIOSDevice) {
            console.log("You are using an iOS device");
            const aTag = document.createElement("a");
            aTag.target = "_blank";
            aTag.href = res.data.url;
            aTag.click();
          } else {
            console.log("You are not using an iOS device");
            const cerebryPlayer = window.open(
              `${res.data.url}`,
              "_blank",
              "width='100%', height='100%'"
            );
            var timer = setInterval(function () {
              if (cerebryPlayer.closed) {
                clearInterval(timer);
                window.location.reload(); // <=======the code which is commented for now...
              }
            }, 1000);
          }
          return;
        } else {
          setErrorPopUpOpen(true);
        }
      },
    }
  );

  {
    /*********************** get test token api ***********************/
  }

  function getTokenForTest() {
    return prepare_apirequest({
      url: `${endpoint.getTokenForTest.addr}`,
      method: "post",
      headers: {
        "access-token": login.token,
      },
      data: {
        assessmentId: asset?.assetDetails[0]?.assessmentId,
        topicId: cerebryData?.topic_id?.toString() ?? "",
        // asset?.benchMarkTestStatus?.topic_id?.toString() ??
        // asset?.adaptiveTestStatus?.topic_id?.toString() ??
        // "",
        // asset?.assetDetails[0]?.cerebryTopicId + "",
      },
    });
  }

  const { refetch: tokenForTest } = useQuery(
    [endpoint.getTokenForTest.key, asset.assetId],
    getTokenForTest,
    {
      enabled: false,
      onSuccess: (res) => {
        // openIframe(res.data.benchmarkCerebryUrl);
        setBenchmarkCerebryUrl(
          res.data.benchmarkCerebryUrl ?? res.data.adaptiveCerebryUrl
        );
      },
    }
  );

  React.useEffect(() => {
    if (benchmarkCerebryUrl) openPopup(benchmarkCerebryUrl);
  }, [benchmarkCerebryUrl]);

  const filteredBookedSlots = bookedSlots?.filter(
    (slot) => Number(slot.asset_id) === Number(asset.assetId)
  );
  let isBooked = false;
  filteredBookedSlots?.map((slot) => {
    if (slot.is_active === "1") {
      isBooked = true;
    }
  });

  const bookingInfo = filteredBookedSlots?.find(
    (slot) => slot.is_active === "1"
  );

  const isAddToMyTaskEnabled =
    (testModule &&
      ((asset.assetDetails[0].proctored === "1" &&
        moment(asset.assetDetails[0].endDate + " " + "23:59:59").isBefore(
          moment.now()
        )) ||
        (asset.assetDetails[0].proctored === "0" &&
          moment(asset.assetDetails[0].releaseDate).isBefore(moment.now())))) ||
    module === "prepare";

  const cerebryData = testCerebryData?.[assessmentId];

  return (
    <>
      {errorPopUpOpen && (
        <ErrorHandlerPopUp
          setErrorPopUpOpen={setErrorPopUpOpen}
          title="This learning resource is not available."
        />
      )}
      <div className="flex justify-between mb-9 w-full font-ibm">
        <div className="flex relative">
          {returnIcon()}
          <span className="absolute -ml-2 -mt-2">
            {returnStatusIcon(asset.status ? asset.status : asset.isCompleted)}
          </span>

          <div className="ml-6">
            <h2
              onClick={!testModule ? () => handleClick() : () => {}}
              className={`text-xl mb-4 line-clamp-2 ${
                isSelectedInPlaylist ? " font-medium underline" : "font-normal "
              } ${!testModule ? "cursor-pointer" : "cursor-default"}`}
            >
              {asset.assetName || asset.name}
            </h2>
            {asset.status == "" &&
              asset?.assetDetails[0]?.proctored === "1" &&
              !isBooked &&
              moment(asset?.assetDetails[0]?.slotStartDate)?.isAfter(
                moment.now()
              ) &&
              moment(asset?.assetDetails[0]?.releaseDate)?.isAfter(
                moment.now()
              ) && (
                <div>
                  <p className="text-sm text-secondary-500">
                    Release Date -{" "}
                    {moment(asset?.assetDetails[0]?.releaseDate).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
              )}
            {asset.status === "" &&
              // !!isBooked &&
              asset?.assetDetails[0]?.proctored === "0" &&
              moment(asset?.assetDetails[0]?.releaseDate).isAfter(
                moment.now()
              ) && (
                <div className="mb-2">
                  <p className="text-sm text-secondary-500">
                    Release Date -{" "}
                    {moment(asset?.assetDetails[0]?.releaseDate).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
              )}
            {/* updated duration */}
            <div className="flex space-x-4">
              {duration !== "0:00:00" && (
                <Tag
                  tagInfo={formatDuration(duration)}
                  isGrayBackground={true}
                />
              )}
              {asset?.assetType?.toLowerCase() === "assessment" && (
                <>
                  {asset.marksObtained !== "" && asset.totalMarks !== "" && (
                    <>
                      {asset.sectionalScore?.length > 0 &&
                      asset.sectionalScore?.length > 1 ? (
                        <>
                          <div className="flex">
                            <ScoreIcon />
                            <p>Score&nbsp;•&nbsp;{asset.marksObtained}</p>
                            <>
                              <span>&#40;&nbsp; </span>
                              {asset.sectionalScore?.map((item, key) => (
                                <>
                                  <p key={key}>{item.marks_obtained}</p>
                                  <p
                                    key={key}
                                    className={
                                      key !== asset.sectionalScore.length - 1
                                        ? "text-black"
                                        : "text-white"
                                    }
                                  >
                                    -
                                  </p>
                                </>
                              ))}
                              <span>&#41; </span>
                            </>
                          </div>
                          {asset?.overAllPercentile ? (
                            <div className="flex">
                              <PercentileIcon />
                              <p>
                                Percentile&nbsp;•&nbsp;{asset.overAllPercentile}
                              </p>
                              <>
                                <span>&#40;&nbsp; </span>
                                {asset.sectionalScore?.map((item, key) => (
                                  <>
                                    <p key={key}>{item.percentile}</p>
                                    <p
                                      key={key}
                                      className={
                                        key !== asset.sectionalScore.length - 1
                                          ? "text-black"
                                          : "text-white"
                                      }
                                    >
                                      -
                                    </p>
                                  </>
                                ))}
                                <span>&#41; </span>
                              </>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <div className="flex">
                            <ScoreIcon />
                            <p>
                              &nbsp;•&nbsp;{asset.marksObtained}/
                              {asset.totalMarks}
                            </p>
                          </div>
                          {asset?.overAllPercentile ? (
                            <div className="flex">
                              <PercentileIcon />
                              <p>Percentile&nbsp;•&nbsp;</p>
                              <p>{asset.overAllPercentile}%</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  )}
                  {cerebryData &&
                    typeof cerebryData === "object" &&
                    cerebryData?.level &&
                    cerebryData?.level !== "n.a." &&
                    asset.status.toLowerCase() === "completed" && (
                      <div className="flex">
                        <ExcellentIcon />
                        <p>{cerebryData?.level}</p>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex space-x-2 ">
          {isButtons && (
            <div className="flex space-x-2 items-center">
              {/* For Analytics */}

              {asset.assetType?.toLowerCase() === "assessment" && (
                <>
                  {asset?.assetDetails[0]?.assessmentType == "Benchmarking" &&
                    asset.status.toLowerCase() === "completed" &&
                    asset.isAdaptive === "1" &&
                    // asset?.benchMarkTestStatus?.topic_id !== "" && (
                    //   asset?.benchMarkTestStatus !== "" &&
                    // asset?.benchMarkTestStatus?.topic_id !== null && (
                    cerebryData?.status &&
                    cerebryData?.topic_id && (
                      <div
                        onClick={() => tokenForTest()}
                        className="bg-primary-200 text-white h-[38px] px-4 flex items-center justify-center rounded-3xl cursor-pointer "
                      >
                        Launch Adaptive Practice
                      </div>
                    )}
                  {asset?.assetDetails[0]?.assessmentType !== "Benchmarking" &&
                    asset.status.toLowerCase() === "completed" &&
                    asset.isAdaptive === "1" &&
                    //asset?.adaptiveTestStatus?.topic_id !== "" && (
                    cerebryData?.status && (
                      <div
                        onClick={() => tokenForTest()}
                        className="underline text-primary-200 cursor-pointer mt-3 mr-5 "
                      >
                        Revise with AI
                      </div>
                    )}
                </>
              )}
              {module !== "prepare" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                asset?.assetType?.toLowerCase() === "assessment" &&
                asset?.status === "completed" &&
                moment(
                  asset?.assetDetails[0]?.endDate + " " + "23:59:59"
                ).isBefore(moment.now()) && (
                  <div
                    data-tip
                    data-for="analytics"
                    onClick={() =>
                      history.push(
                        `/test/Detailed Analytics/${
                          asset.assetName || asset.name
                        }?assetId=${asset.assetId}&assessmentId=${
                          asset.assetDetails[0].assessmentId
                        }`
                      )
                    }
                    className="h-12 w-12 cursor-pointer ml-2"
                  >
                    <Icon src={myAnalytics} />
                  </div>
                )}
              {module !== "prepare" &&
                asset?.assetDetails[0]?.proctored === "0" &&
                asset?.assetType?.toLowerCase() === "assessment" &&
                asset?.status === "completed" && (
                  <div
                    data-tip
                    data-for="analytics"
                    onClick={() =>
                      history.push(
                        `/test/Detailed Analytics/${
                          asset.assetName || asset.name
                        }?assetId=${asset?.assetId}&assessmentId=${
                          asset?.assetDetails[0]?.assessmentId
                        }`
                      )
                    }
                    className="h-12 w-12 cursor-pointer ml-2"
                  >
                    <Icon src={myAnalytics} />
                  </div>
                )}
              {
                <Tooltip
                  multiline={false}
                  id="analytics"
                  place="bottom"
                  text="View my analytics"
                  offset={{ right: 10 }}
                />
              }

              {testModule &&
                asset.status === "" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                !isBooked &&
                moment(asset?.assetDetails[0]?.slotStartDate)?.isBefore(
                  moment.now()
                ) &&
                moment(asset?.assetDetails[0]?.slotEndDate)?.isAfter(
                  moment.now()
                ) && (
                  <BookingSlotModalTrigger
                    asset={asset}
                    variantDetails={variantDetails?.data?.data?.variant}
                  />
                )}
              {testModule &&
                asset.status === "" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                !isBooked &&
                moment(asset?.assetDetails[0]?.slotStartDate)?.isAfter(
                  moment.now()
                ) &&
                moment(asset?.assetDetails[0]?.releaseDate)?.isAfter(
                  moment.now()
                ) && (
                  <>
                    <p
                      data-tip
                      data-for="book-test"
                      className="cursor-pointer underline text-secondary-400 font-ibm h-12"
                    >
                      Book Slot
                    </p>
                    <Tooltip
                      multiline={false}
                      id="book-test"
                      place="bottom"
                      text="The slot booking window has not yet started."
                      offset={{ right: 10 }}
                    />
                  </>
                )}

              {testModule && !!isBooked && asset.status === "" && (
                <BookingConfimMain
                  onClick={() => handleClick()}
                  asset={asset}
                  filteredBookedSlots={filteredBookedSlots}
                />
              )}

              {testModule &&
                asset.status.toLowerCase() === "completed" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                moment(
                  asset.assetDetails[0].endDate + " " + "23:59:59"
                ).isAfter(moment.now()) && (
                  <>
                    <p
                      data-tip
                      data-for="review-test"
                      className="cursor-pointer underline text-secondary-400 font-ibm h-12 mt-3"
                    >
                      Review Test
                    </p>
                    <Tooltip
                      multiline={false}
                      id="review-test"
                      place="bottom"
                      text="Review has not been enabled for this test yet."
                      offset={{ right: 10 }}
                    />
                  </>
                )}
              {testModule &&
                asset.status.toLowerCase() === "completed" &&
                // !!isBooked &&
                asset?.assetDetails[0]?.proctored === "0" && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Review Test
                    </p>
                  </>
                )}
              {testModule &&
                asset.status === "" &&
                !!isBooked &&
                moment(
                  asset?.assetDetails[0]?.endDate + " " + "23:59:59"
                ).isBefore(moment.now()) &&
                moment(bookingInfo.slot_end_time).isBefore(moment.now()) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Launch Test
                    </p>
                  </>
                )}
              {/* {testModule &&
                asset.status.toLowerCase() === "in-progress" &&
                moment(asset?.assetDetails[0]?.endDate).isBefore(
                  moment.now()
                ) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Resume Test
                    </p>
                  </>
                )} */}
              {testModule &&
                asset.status === "" &&
                // !!isBooked &&
                asset?.assetDetails[0]?.proctored === "0" &&
                moment(asset?.assetDetails[0]?.releaseDate).isAfter(
                  moment.now()
                ) && (
                  <>
                    <p
                      data-tip
                      data-for="launch-test-disabled"
                      className="cursor-pointer underline text-secondary-400 font-ibm h-12 mt-3"
                    >
                      Launch Test
                    </p>
                    <Tooltip
                      multiline={false}
                      id="launch-test-disabled"
                      place="bottom"
                      text="The test is not yet available to launch."
                      offset={{ right: 10 }}
                    />
                  </>
                )}
              {testModule &&
                asset.status === "" &&
                // !!isBooked &&
                asset?.assetDetails[0]?.proctored === "0" &&
                moment(asset?.assetDetails[0]?.releaseDate).isBefore(
                  moment.now()
                ) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Launch Test
                    </p>
                  </>
                )}
              {testModule &&
                asset.status === "" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                moment(asset?.assetDetails[0]?.releaseDate).isBefore(
                  moment.now()
                ) &&
                moment(
                  asset?.assetDetails[0]?.endDate + " " + "23:59:59"
                ).isBefore(moment.now()) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Launch Test
                    </p>
                  </>
                )}
              {testModule &&
                asset.status.toLowerCase() === "in-progress" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                moment(asset?.assetDetails[0]?.releaseDate).isBefore(
                  moment.now()
                ) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Resume Test
                    </p>
                  </>
                )}
              {testModule &&
                asset.status.toLowerCase() === "completed" &&
                asset?.assetDetails[0]?.proctored === "1" &&
                moment(asset?.assetDetails[0]?.releaseDate).isBefore(
                  moment.now()
                ) &&
                moment(
                  asset?.assetDetails[0]?.endDate + " " + "23:59:59"
                ).isBefore(moment.now()) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Review Test
                    </p>
                  </>
                )}
              {testModule &&
                asset.status?.toLowerCase() == "in-progress" &&
                asset?.assetDetails[0]?.proctored === "0" &&
                moment(asset?.assetDetails[0]?.releaseDate).isBefore(
                  moment.now()
                ) && (
                  <>
                    <p
                      onClick={() => handleClick()}
                      className="cursor-pointer underline text-primary-200 font-ibm h-12 mt-3"
                    >
                      Resume Test
                    </p>
                  </>
                )}
              {asset.assetType?.toLowerCase() !== "assessment" && (
                <div
                  data-tip
                  data-for="bookmark"
                  onClick={() => setBookmarked(!bookmarked)}
                  className="h-12 w-12"
                >
                  {
                    <CardBookMark
                      className="w-10 h-10 -mt-2"
                      moduleId={moduleId}
                      assetId={
                        asset.assetId ? asset.assetId : asset.learningPathId
                      }
                      variantId={variantId}
                      courseId={courseId}
                      learningPathId={asset.assetId ? parentId : asset.parentId}
                      bookmarked={asset.bookmarked ? asset.bookmarked : "0"}
                      isPlayList={asset.assetId ? false : true}
                    />
                  }
                </div>
              )}
              {asset.assetType?.toLowerCase() == "assessment" &&
                asset?.assetDetails[0]?.assessmentType?.toLowerCase() ==
                  "in-class quizzes" && (
                  <div
                    data-tip
                    data-for="bookmark"
                    onClick={() => setBookmarked(!bookmarked)}
                    className="h-12 w-12"
                  >
                    {
                      <CardBookMark
                        className="w-10 h-10 -mt-2"
                        moduleId={moduleId}
                        assetId={
                          asset.assetId ? asset.assetId : asset.learningPathId
                        }
                        variantId={variantId}
                        courseId={courseId}
                        learningPathId={
                          asset.assetId ? parentId : asset.parentId
                        }
                        bookmarked={asset.bookmarked ? asset.bookmarked : "0"}
                        isPlayList={asset.assetId ? false : true}
                      />
                    }
                  </div>
                )}
              {
                <Tooltip
                  multiline={false}
                  id="bookmark"
                  place="bottom"
                  text="Add to Bookmarks"
                  offset={{ right: 10 }}
                />
              }
              <div
                data-tip
                data-for={`task` + asset.assetId}
                onClick={
                  isAddToMyTaskEnabled ? () => handleMyTaskClick() : () => {}
                }
                className={`h-12 w-12 ${
                  isAddToMyTaskEnabled ? "cursor-pointer" : "cursor-default"
                } `}
              >
                {isAddToMyTaskEnabled && (
                  <Icon
                    src={asset.isMyTask === "1" || task ? myTaskActive : myTask}
                  />
                )}
              </div>
              {isAddToMyTaskEnabled && (
                <Tooltip
                  multiline={false}
                  id={`task` + asset.assetId}
                  place="bottom"
                  text="Add to myTask"
                  offset={{ right: 10 }}
                />
              )}
            </div>
          )}
        </div>
        {benchmarkCerebryUrl && (
          <iframe
            className="hidden"
            ref={iframeRef}
            id="iframe"
            width="100%"
            height="100%"
            src={benchmarkCerebryUrl}
            name="national-campaign"
          ></iframe>
        )}
      </div>
    </>
  );
};

Topic.propTypes = {
  asset: propTypes.object,
  parentId: propTypes.string,
  moduleId: propTypes.string,
  isButtons: propTypes.bool,
  title: propTypes.string,
  parent: propTypes.string,
  isFromPrepare: propTypes.bool,
  isFromChannel: propTypes.bool,
  isFromPlaylist: propTypes.bool,
  isSelectedInPlaylist: propTypes.bool,
  isSetContinueLearningParentId: propTypes.bool,
  clearPrepareSlicePlaylist: propTypes.bool,
  moduleIdForChannel: propTypes.string,
  variantDetails: propTypes.any,
  bookedSlots: propTypes.any,
  testModule: propTypes.bool,
  testCerebryData: propTypes.any,
};

export default Topic;
